<template>
  <h1>User Settings Page</h1>
</template>

<script>
  export default {
    name: 'UserSettings'
  }
</script>

<style scoped>

</style>
